import { Euro } from "@mui/icons-material";
import { Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import SimpleAccordion from "src/qubCommon/Accordions/SimpleAccordion";
import { calculate } from "src/qubCommon/helperMethods/dateHelper";
import TextInput from "src/qubCommon/Inputs/TextInput";
import {
  formatFloat,
  showFloat,
} from "src/qubCommon/wijmoHelpers/numberMethods";
import "./Expenses.css";
import CurrencyInput from "qubCommon/Inputs/CurrencyInput";
import CustomSimpleAccordion from "qubCommon/Accordions/CustomSimpleAccordion";

const Budget = ({ expenses, budget, expensesForm, settings }) => {
  const canEdit = settings?.canEditDataEntry;
  const hasSecond = settings?.hasSecondDataEntry;
  const canEditSecond = settings?.canEditSecondDataEntry;

  const [childrenInputs, setChildrenInputs] = useState([]);

  useEffect(() => {
    let total = 0;
    let total2 = 0;
    budget?.forEach((value) => {
      expensesForm.setValue(`budget.${value?.id}`, showFloat(value?.amount));
      expensesForm.setValue(`budget2.${value?.id}`, showFloat(value?.amount2));
      total += value?.amount ?? 0;
      total2 += value?.amount2 ?? 0;
    });
    expensesForm.setValue("budgetTotal", showFloat(total));
    expensesForm.setValue("budgetTotal2", showFloat(total2));
    let children = [];
    expenses.forEach((expense) => {
      if (expense.children && expense.children.length > 0) {
        expense.children.forEach((child) => {
          children.push({
            id: child?.id,
            parentId: expense?.header?.id,
            isSummary: child?.isSummary,
            hasDataEntry: child?.hasDataEntry,
          });
        });
      }
    });
    setChildrenInputs(children);
    const summaries = children.filter((child) => child?.isSummary);

    summaries.forEach((summary) => {
      let calculatedSum = 0;
      let calculatedSum2 = 0;
      children.forEach((child) => {
        if (child?.parentId === summary?.parentId && child?.hasDataEntry) {
          const childAmount =
            budget?.find((b) => b.id === child?.id)?.amount ?? 0;
          calculatedSum += childAmount;
          const childAmount2 =
            budget?.find((b) => b.id === child?.id)?.amount2 ?? 0;
          calculatedSum2 += childAmount2;
        }
      });
      expensesForm.setValue(
        `budgetSummary.${summary?.id}`,
        showFloat(calculatedSum)
      );
      expensesForm.setValue(
        `budgetSummary2.${summary?.id}`,
        showFloat(calculatedSum)
      );
    });
  }, []);

  const AccordionTitle = () => (
    <Stack direction="row" gap={1}>
      <Euro color="primary" fontSize="small" sx={{ marginLeft: "2px" }} />
      <Typography color="primary" sx={{ fontWeight: 1000 }}>
        ΑΝΑΛΥΤΙΚΟΣ ΠΡΟΫΠΟΛΟΓΙΣΜΟΣ ΔΑΠΑΝΩΝ
      </Typography>
    </Stack>
  );

  const inputOnBlur = (e, id, sec = false) => {
    if (Number.isNaN(formatFloat(e.target.value))) {
      if (sec) expensesForm.setValue(`budget2.${id}`, "");
      if (!sec) expensesForm.setValue(`budget.${id}`, "");
    } else {
      if (!sec)
        expensesForm.setValue(
          `budget.${id}`,
          showFloat(formatFloat(e.target.value))
        );
      if (sec)
        expensesForm.setValue(
          `budget2.${id}`,
          showFloat(formatFloat(e.target.value))
        );
    }
    const summaries = childrenInputs.filter((child) => child?.isSummary);

    summaries.forEach((summary) => {
      let calculatedSum = 0;
      let calculatedSum2 = 0;
      childrenInputs?.forEach((child) => {
        if (child?.parentId === summary?.parentId && child?.hasDataEntry) {
          const childAmount = expensesForm.getValues(`budget.${child?.id}`);
          const childAmount2 = expensesForm.getValues(`budget2.${child?.id}`);
          if (childAmount) calculatedSum += formatFloat(childAmount);
          if (childAmount2) calculatedSum2 += formatFloat(childAmount2);
        }
      });
      expensesForm.setValue(
        `budgetSummary.${summary?.id}`,
        showFloat(calculatedSum)
      );
      expensesForm.setValue(
        `budgetSummary2.${summary?.id}`,
        showFloat(calculatedSum2)
      );
    });
    const childrenValues = expensesForm.getValues("budget");
    const childrenValues2 = expensesForm.getValues("budget2");
    let total = 0;
    childrenValues.forEach((value) => {
      if (value) total += formatFloat(value);
    });
    let total2 = 0;
    childrenValues2.forEach((value) => {
      if (value) total2 += formatFloat(value);
    });

    expensesForm.setValue("budgetTotal", showFloat(total));
    expensesForm.setValue("budgetTotal2", showFloat(total2));
  };

  const AccordionContent = () => (
    <FormProvider {...expensesForm}>
      <Grid container>
        <table className="table table-sm bordered-table" id="budgetTable">
          <thead>
            <tr>
              <th scope="col">ΚΑΤΗΓΟΡΙΑ ΔΑΠΑΝΗΣ </th>
              <th scope="col" className="responsive-col">
                ΠΟΣΟ
              </th>
              {hasSecond && (
                <th scope="col" className="responsive-col">
                  ΠΟΣΟ ΕΠΙΤΡ.
                </th>
              )}
              <th scope="col">ΠΕΡΙΓΡΑΦΗ - ΕΠΕΞΗΓΗΣΗ</th>
            </tr>
          </thead>
          <tbody>
            {expenses?.map((expense) => (
              <React.Fragment key={expense?.header?.id}>
                <tr>
                  <td>
                    <b>{expense?.header?.name}</b>
                    <br />
                    {expense?.header?.description}
                  </td>
                  <td>
                    {expense?.header?.isTotal ? (
                      <CurrencyInput
                        disabled
                        inputAlign="right"
                        name={`budgetTotal`}
                        defaultFromProps={false}
                      />
                    ) : null}

                    {expense?.header?.hasDataEntry ? (
                      <CurrencyInput
                        disabled={!canEdit}
                        inputAlign="right"
                        name={`budget.${expense?.header?.id}`}
                        blurFn={(e) => inputOnBlur(e, expense?.header?.id)}
                        defaultFromProps={false}
                      />
                    ) : null}
                  </td>
                  {expense?.header?.isTotal && hasSecond && (
                    <td>
                      <CurrencyInput
                        disabled
                        inputAlign="right"
                        name={`budgetTotal2`}
                        defaultFromProps={false}
                      />
                    </td>
                  )}
                  {expense?.header?.hasDataEntry && hasSecond && (
                    <td>
                      <CurrencyInput
                        disabled={!canEditSecond}
                        inputAlign="right"
                        name={`budget2.${expense?.header?.id}`}
                        blurFn={(e) =>
                          inputOnBlur(e, expense?.header?.id, true)
                        }
                        defaultFromProps={false}
                      />
                    </td>
                  )}
                  <td>{expense?.header?.hint}</td>
                </tr>
                {expense?.children?.map((child) => (
                  <tr key={child?.id}>
                    <td>
                      <b> {child?.name} </b>
                      <br />
                      {child?.description}
                    </td>
                    <td>
                      {child?.isSummary ? (
                        <CurrencyInput
                          disabled
                          inputAlign="right"
                          name={`budgetSummary.${child?.id}`}
                          defaultFromProps={false}
                        />
                      ) : null}
                      {child?.hasDataEntry ? (
                        <CurrencyInput
                          disabled={!canEdit}
                          inputAlign="right"
                          name={`budget.${child?.id}`}
                          blurFn={(e) => inputOnBlur(e, child?.id)}
                          defaultFromProps={false}
                        />
                      ) : null}
                    </td>
                    {child?.isSummary && hasSecond && (
                      <td>
                        <CurrencyInput
                          disabled
                          inputAlign="right"
                          name={`budgetSummary2.${child?.id}`}
                          defaultFromProps={false}
                        />
                      </td>
                    )}
                    {child?.hasDataEntry && hasSecond && (
                      <td>
                        <CurrencyInput
                          disabled={!canEditSecond}
                          inputAlign="right"
                          name={`budget2.${child?.id}`}
                          blurFn={(e) => inputOnBlur(e, child?.id, true)}
                          defaultFromProps={false}
                        />
                      </td>
                    )}
                    <td>{child?.hint}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </Grid>
    </FormProvider>
  );

  return (
    <CustomSimpleAccordion
      title={<AccordionTitle />}
      content={<AccordionContent />}
    />
  );
};

export default Budget;

import { Button } from "@mui/material";
import { updateApplication } from "apis/controllers/applications";
import { FileProvider, useFileContext } from "context/FilesV2";
import { useLoading } from "hooks/useLoading";
import ConfirmDialog from "qubCommon/Dialogs/ConfirmationDialog";
import FilePreviewer from "qubCommon/FilePreviewer";
import { formatFloat } from "qubCommon/wijmoHelpers/numberMethods";
import { useEffect, useRef, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteBundle,
  deleteFile,
  downloadFile,
  formatFiles,
  uploadFiles,
} from "util";
import "./ApplicationDetails.css";
import AlertBanner from "./Components/AlertBanner";
import Amendment from "./Components/Amendment";
import Apartments from "./Components/Apartments";
import ApplicationActions from "./Components/ApplicationActions";
import Attachments from "./Components/Attachments";
import Budget from "./Components/Budget";
import Employees from "./Components/Employees";
import History from "./Components/History";
import Institute from "./Components/Institute";
import LegalRepresentative from "./Components/LegalRepresentative";
import ListFileItem from "./Components/ListFileItem";
import ScientificDirector from "./Components/ScientificDirector";
import SummaryCard from "./Components/SummaryCard";
import useApplicationDetails from "./useApplicationDetails";

const ApplicationDetails = () => {
  const {
    form,
    data,
    succeeded,
    expensesForm,
    files,
    fetchApplication,
    resetForm,
  } = useApplicationDetails();

  const { id } = useParams();

  if (succeeded)
    return (
      <div className="applications-container">
        <FileProvider
          downloadFile={(fileId, type) => downloadFile(fileId, type)}
          uploadFiles={(fileGroups) => uploadFiles(fileGroups, null)}
          onDeleteBundle={async (bundleId) =>
            await deleteBundle(bundleId, 4, null, id, null)
          }
          onDeleteFile={async (fileId) => {
            await deleteFile(fileId, 4, null, id, null);
          }}
        >
          <Form
            form={form}
            expensesForm={expensesForm}
            data={data}
            files={files}
            fetchApplication={fetchApplication}
            resetForm={resetForm}
          />
        </FileProvider>
      </div>
    );
};

const Form = (props) => {
  const { form, expensesForm, data, files, fetchApplication, resetForm } =
    props;
  const [openAmendment, setOpenAmendment] = useState(false);
  const [fileToPreview, setFileToPreview] = useState(null);
  const [errorBanner, setErrorBanner] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogProps, setDialogProps] = useState({
    title: "",
    content: "",
    onConfirm: () => {},
  });
  const { id } = useParams();
  const { fileGroups, control } = useFileContext();
  const fgRefs = useRef([]);
  const { setLoading } = useLoading();
  const settings = data?.application?.settings;
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const formattedFiles = formatFiles(files);
      if (formattedFiles !== undefined) {
        control.initialize(formattedFiles);
      } else {
        control.initialize([]);
      }
    } catch (error) {
      console.error("error", error);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const prepareBudgetAndBundles = () => {
    const values = expensesForm.getValues("budget");
    const values2 = expensesForm?.getValues("budget2") ?? [];
    const formattedValues = [];

    data?.expenses.forEach((expense) => {
      if (expense.header.hasDataEntry) {
        const value = values[`${expense.header.id}`];
        const value2 = values2?.[`${expense.header.id}`];
        if (value) {
          formattedValues.push(
            value2
              ? {
                  id: expense.header.id,
                  amount: formatFloat(value),
                  amount2: formatFloat(value2),
                }
              : { id: expense.header.id, amount: formatFloat(value) }
          );
        }
      }

      expense.children.forEach((child) => {
        if (child.hasDataEntry) {
          const value = values[`${child.id}`];
          const value2 = values2?.[`${child.id}`];
          if (value) {
            formattedValues.push(
              value2
                ? {
                    id: child.id,
                    amount: formatFloat(value),
                    amount2: formatFloat(value2),
                  }
                : {
                    id: child.id,
                    amount: formatFloat(value),
                  }
            );
          }
        }
      });
    });

    const bundleIds = fileGroups.reduce((acc, group) => {
      return [
        ...acc,
        ...group.bundles
          .filter((bundle) => bundle?.files?.length > 0)
          .map((bundle) => bundle.id),
      ];
    }, []);

    return { formattedValues, bundleIds };
  };

  const handleApplicationUpdate = async (
    action,
    additionalData = {},
    uploadFiles = true
  ) => {
    try {
      setLoading(true);
      const applicationData = action !== "approve" ? form.getValues() : { id };
      if (uploadFiles) {
        const resFiles = await control.uploadFileGroups();
        if (resFiles?.status === "error") {
          setErrorBanner(resFiles?.message);
          setLoading(false);
          return;
        }
      }

      const uploadData = {
        ...applicationData,
        ...additionalData,
        action,
      };

      const res = await updateApplication(uploadData);

      if (res?.status === "success") await fetchApplication();
      if (res?.status === "error") {
        setErrorBanner(res?.message);
        if (action === "submit") resetForm();
      }

      setLoading(false);
      setOpenDialog(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setErrorBanner("<div>Προέκυψε Σφάλμα!</div>");
    }
  };

  const handleOpenDialog = (actionType) => {
    switch (actionType) {
      case "accept":
        setDialogProps({
          title: "Αποδοχή Τροποποιήσεων",
          content: "Θέλετε σίγουρα να αποδεχτείτε τις τροποποιήσεις;",
          onConfirm: handleAccept,
        });
        break;
      case "refuse":
        setDialogProps({
          title: "Απόρριψη Τροποποιήσεων",
          content: "Θέλετε σίγουρα να απορρίψετε τις τροποποιήσεις;",
          onConfirm: handleRefuse,
        });
        break;
      case "approve":
        setDialogProps({
          title: "Έγκριση Αίτησης",
          content: "Θέλετε σίγουρα να εγκρίνετε την αίτηση;",
          onConfirm: handleApprove,
          commentsOn: settings?.approve?.commentsOn,
          withFileUpload: settings?.approve?.withFileUpload,
          fileDatakey: settings?.approve?.fileDataKey,
          fileDataValue: settings?.approve?.fileDataValue,
        });
        break;
      case "reject":
        setDialogProps({
          title: "Απόρριψη Αίτησης",
          content: ` Θέλετε σίγουρα να επιστρέψετε την αίτηση στον αιτούντα; 
            Παρακαλώ αναφέρετε τους λόγους απόρριψης.`,
          onConfirm: settings?.reject?.withFileUpload
            ? handleRejectWithFileUpload
            : handleReject,
          commentsOn: true,
          withFileUpload: settings?.reject?.withFileUpload,
          fileDatakey: settings?.reject?.fileDataKey,
          fileDataValue: settings?.reject?.fileDataValue,
        });
        break;
      case "submit":
        setDialogProps({
          title: "Υποβολή Αίτησης",
          content: "Θέλετε σίγουρα να υποβάλλετε την αίτηση;",
          onConfirm: handleSubmit,
          withFileUpload: settings?.submit?.withFileUpload,
          fileDatakey: settings?.submit?.fileDataKey,
          fileDataValue: settings?.submit?.fileDataValue,
        });
        break;
      case "cancel":
        setDialogProps({
          title: "Ακύρωση Αίτησης",
          content: "Θέλετε σίγουρα να ακυρώσετε την αίτηση;",
          onConfirm: handleCancel,
        });
        break;
      case "backToHome":
        const dirtyFields = Object.keys(form.formState.dirtyFields).map(
          (x) => ({
            field: x,
            value: form.getValues(x),
          })
        );
        if (!!dirtyFields?.length || control.isDirty)
          setDialogProps({
            title: "Προσοχή!",
            content:
              "Έχετε πραγματοποιήσει αλλαγές στη φόρμα σας. Αν αποχωρήσετε οι αλλαγές θα χαθούν. Θέλετε να κλείσετε τη σελίδα;",
            onConfirm: () => navigate("/home"),
          });
        else navigate("/home");
        break;
      case "partiallyApprove":
        setDialogProps({
          title: settings?.partiallyApprove?.partiallyApproveBtnTxt,
          content:
            "Θέλετε σίγουρα να προχωρήσετε σε αλλαγή κατάστασης της αίτησης;",
          onConfirm: handlePartiallyApprove,
          withFileUpload: settings?.partiallyApprove?.withFileUpload,
          fileDatakey: settings?.partiallyApprove?.fileDataKey,
          fileDataValue: settings?.partiallyApprove?.fileDataValue,
        });
        break;
      default:
        return;
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = async () => {
    await setOpenDialog(false);
    await setDialogProps((prev) => ({ ...prev, withFileUpload: false }));
  };

  const handlePreviewFile = async (file) => {
    const res = await downloadFile(file?.id, file?.type ?? file?.contentType);
    setFileToPreview(res);
  };

  // Refactored handlers

  const handleSave = async () => {
    const { formattedValues, bundleIds } = prepareBudgetAndBundles();
    await handleApplicationUpdate("save", {
      budget: formattedValues,
      bundles: bundleIds,
    });
  };

  const handleAccept = async () => {
    await handleApplicationUpdate(
      "accept",
      { id: form.getValues("id") },
      false
    );
  };

  const handleRefuse = async () => {
    await handleApplicationUpdate(
      "refuse",
      { id: form.getValues("id") },
      false
    );
  };

  const handleApproveSave = async () => {
    try {
      setLoading(true);

      // ToDo SOS Uncomment this
      const resFiles = await control.uploadFileGroups();
      if (resFiles?.status === "error") {
        setErrorBanner(resFiles?.message);
        setLoading(false);
        return;
      }
      await fetchApplication();

      setLoading(false);
    } catch {
      setLoading(false);
      setErrorBanner("<div>Προέκυψε σφάλμα!</div>");
    }
  };

  const handleApprove = async (fileBundles = [], comments) => {
    await handleApplicationUpdate(
      "approve",

      fileBundles?.length > 0
        ? { bundles: fileBundles, comments }
        : { comments },
      true
    );
  };

  const handleRejectWithFileUpload = async (fileBundles = [], comments) => {
    const { formattedValues, bundleIds } = prepareBudgetAndBundles();
    const additionalData =
      fileBundles?.length > 0
        ? {
            id: form.getValues("id"),
            action: "reject",
            comments,
            bundles: fileBundles,
            budget: formattedValues,
          }
        : {
            id: form.getValues("id"),
            action: "reject",
            comments,
            budget: formattedValues,
          };
    await handleApplicationUpdate("reject", additionalData, false);
  };

  const handleReject = async (comments) => {
    await handleApplicationUpdate(
      "reject",
      {
        id: form.getValues("id"),
        comments,
      },
      true
    );
  };

  const handleSubmit = async (fileBundles = []) => {
    const { formattedValues, bundleIds } = prepareBudgetAndBundles();
    let submitBundles = bundleIds;
    if (fileBundles?.length > 0) submitBundles = [...bundleIds, ...fileBundles];
    await handleApplicationUpdate(
      "submit",
      submitBundles?.length > 0
        ? {
            budget: formattedValues,
            bundles: submitBundles,
          }
        : { budget: formattedValues }
    );
  };

  const handleCancel = async () => {
    await handleApplicationUpdate(
      "cancel",
      { id: form.getValues("id") },
      false
    );
  };

  const handlePartiallyApprove = async (fileBundles = []) => {
    await handleApplicationUpdate(
      "partially_approve",
      fileBundles?.length > 0
        ? {
            bundles: fileBundles,
          }
        : {},
      false
    );
  };

  return (
    <FormProvider {...form}>
      <FilePreviewer
        file={fileToPreview}
        onClose={() => setFileToPreview(null)}
      />
      <ConfirmDialog
        open={openDialog}
        title={dialogProps.title}
        content={dialogProps.content}
        onClose={handleCloseDialog}
        onConfirm={dialogProps.onConfirm}
        commentsOn={dialogProps.commentsOn}
        withFileUpload={dialogProps?.withFileUpload}
        fileDatakey={dialogProps?.fileDatakey}
        fileDataValue={dialogProps?.fileDataValue}
      />
      <AlertBanner canClose={false} info={data?.application?.status}>
        <div style={{ marginTop: 4, marginBottom: 4 }}>
          {data?.application?.status?.files.map((file, index) => (
            <ListFileItem
              key={index}
              file={file}
              index={index}
              handlePreviewFile={() => handlePreviewFile(file)}
            />
          ))}
        </div>
        <div className="buttons-container">
          {data?.application?.status?.refuseBtnText && (
            <Button
              variant="contained"
              color="error"
              onClick={() => handleOpenDialog("refuse")}
            >
              {data?.application?.status?.refuseBtnText}
            </Button>
          )}
          {data?.application?.status?.acceptBtnText && (
            <Button
              variant="contained"
              onClick={() => handleOpenDialog("accept")}
            >
              {data?.application?.status?.acceptBtnText}
            </Button>
          )}
        </div>
      </AlertBanner>
      <SummaryCard
        protocolNumber={data?.application?.protocolNumber}
        protocolNumber2={data?.application?.protocolNumber2}
        status={data?.application?.status}
        created={data?.application?.created}
        updated={data?.application?.updated}
        submitted={data?.application?.submitted}
      />
      <Institute institute={data?.application?.hq} type="hq" />
      {data?.application?.additionalInstitutes?.map((institute, index) => {
        return (
          <Institute
            key={institute?.name + index}
            institute={institute}
            index={index}
            type="additional"
          />
        );
      })}
      <Institute institute={data?.application?.branch} type="branch" />
      <LegalRepresentative rep={data?.application?.legalRepresentative} />
      <ScientificDirector
        dir={data?.application?.scientificDirector}
        jobTypes={data?.lookups?.jobTypes}
        canEdit={settings?.scientificDirector?.canEdit}
        canPartiallyEdit={settings?.scientificDirector?.canPartiallyEdit}
      />
      <Apartments
        apartments={data?.application?.appartments}
        branch={data?.application?.branch}
        settings={settings?.apartments}
      />
      <Employees
        employees={data?.application?.employees}
        settings={settings?.employees}
      />
      <Budget
        expenses={data?.expenses}
        budget={data?.application?.budget}
        expensesForm={expensesForm}
        settings={settings?.expenses}
      />

      <Attachments fileGroups={fileGroups} settings={settings?.attachments} />
      <ApplicationActions
        handleCancel={() => handleOpenDialog("cancel")}
        canEdit={
          settings?.canEdit ||
          settings?.expenses?.canEditDataEntry ||
          settings?.expenses?.canEditSecondDataEntry
        }
        canCancel={settings?.cancel?.can}
        canSubmit={settings?.submit?.can}
        settings={settings}
        handleSubmit={() => handleOpenDialog("submit")}
        handleApprove={() => handleOpenDialog("approve")}
        handleSave={() => handleSave()}
        handleApproveSave={() => handleApproveSave()}
        handleReject={() => handleOpenDialog("reject")}
        handlePartiallyApprove={() => handleOpenDialog("partiallyApprove")}
        handleAmendment={() => setOpenAmendment(true)}
        handleBackToHome={() => handleOpenDialog("backToHome")}
      />
      <AlertBanner
        openInfo={!!errorBanner}
        setOpenMessage={setErrorBanner}
        info={{
          message: `<div>${errorBanner}</div>`,
          cssClass: "error",
        }}
      />
      <History
        history={data?.application?.history}
        handlePreviewFile={handlePreviewFile}
      />
      <Amendment
        open={openAmendment}
        handleClose={() => setOpenAmendment(false)}
      />
    </FormProvider>
  );
};

export default ApplicationDetails;

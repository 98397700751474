import { FileGroup, FileFormField } from "context/FilesV2/types";
import { v4 as uuidv4 } from "uuid";
import { isNil } from "lodash";
import { files } from "apis";
import { deleteFile as deleteFileWithId } from "apis/controllers/files";

interface InputGroup {
  type: number;
  descr?: string;
  lblDateFrom?: string;
  lblDateTo?: string;
  lblIssuedAt?: string;
  lblIssuedBy?: string;
  lblNumber?: string;
  lblComments?: string;
  lblCheckbox?: string;
  lblApprovedAt?: string;
  lblApprovedBy?: string;
  lblApprovedComment?: string;
  lblRejectedAt?: string;
  lblRejectedBy?: string;
  lblRejectedComment?: string;
  lkCommon: {
    lbl: string;
    lookup:
      | {
          value: number | string;
          label: string;
          options: { hide: string | null };
        }[]
      | null;
  };
  files: any[];
  templates?: { title: string; filename: string; id: number }[];
}

interface InputFile {
  id: number;
  origFName: string;
  contentType: string;
  position: number;
}

const getFormFields = (group: InputGroup): FileFormField[] => [
  {
    type: "dropdown",
    name: "common",
    label: group.lkCommon?.lbl,
    options: group.lkCommon?.lookup?.map((item) => ({
      value: item.value,
      label: item.label,
    })),
    isRequired: true,
  },
  {
    type: "date",
    name: "dateFrom",
    label: group.lblDateFrom,
    isRequired: true,
  },
  {
    type: "date",
    name: "dateTo",
    label: group.lblDateTo,
    isRequired: true,
  },
  {
    type: "date",
    name: "issuedAt",
    label: group.lblIssuedAt,
    isRequired: true,
  },
  {
    type: "string",
    name: "issuedBy",
    label: group.lblIssuedBy,
    isRequired: true,
  },
  {
    type: "string",
    name: "number",
    label: group.lblNumber,
    isRequired: true,
  },
  {
    type: "string",
    name: "comments",
    label: group.lblComments,
    isRequired: false,
  },
  {
    type: "boolean",
    name: "checkboxChecked",
    label: group.lblCheckbox,
  },
];

const formatFiles = (fileGroups: InputGroup[]): FileGroup[] => {
  return fileGroups.map((group) => {
    return {
      id: group.type,
      title: group?.descr,
      templates: group.templates,
      maxBundles: group?.max,
      fields: getFormFields(group).filter((field) => !!field.label),
      bundles: group.files?.map((bundle) => ({
        id: bundle.bundleId,
        status: bundle.status,
        fileType: group.type,
        reviewComments: bundle.reviewComments,
        files:
          bundle.pages?.map((file: InputFile) => ({
            id: uuidv4(),
            dbId: file.id,
            name: file?.origFName,
            type: file?.contentType,
            position: file?.position,
            fileObject: null,
            saved: true,
          })) ?? [],
        values:
          {
            dateFrom: bundle?.dateFrom,
            dateTo: bundle?.dateTo,
            issuedAt: bundle?.issuedAt,
            issuedBy: bundle?.issuedBy,
            comments: bundle?.comments,
            number: bundle?.number,
            common: group?.lkCommon?.lookup?.find(
              (option) => option.value === bundle?.pages[0]?.common
            )?.value,
            checkboxChecked: bundle?.checkboxChecked,
          } ?? [],
        saved: true,
      })),
    };
  });
};

const base64ToFile = (base64String: string, fileName: string) => {
  // Split the base64 string in data and contentType
  const [base64Header, base64Data] = base64String.split(",");
  const matchResult = base64Header.match(/:(.*?);/);
  const mimeType = matchResult ? matchResult[1] : "";

  // Decode the base64 string
  const byteCharacters = atob(base64Data);

  // Create an array of 8-bit unsigned integers
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  // Create a `Uint8Array` from the byte numbers array
  const byteArray = new Uint8Array(byteNumbers);

  // Create a `Blob` from the `Uint8Array`
  const blob = new Blob([byteArray], { type: mimeType });

  // Create a `File` from the `Blob`
  const file = new File([blob], fileName, { type: mimeType });

  return file;
};

const downloadFile = async (
  id: number,
  type: string
): Promise<File | undefined> => {
  try {
    let data;
    let file;
    if (type === "application/pdf") {
      data = await files.downloadFile({ Id: id }, { responseType: "blob" });
      file = new File([data], "file.pdf", { type: "application/pdf" });
    } else if (type.startsWith("image")) {
      data = await files.downloadFileAsBase64({ Id: id });
      file = base64ToFile(data?.data, "file.jpg");
    } else {
      file = new File([""], "file", { type: type });
    }
    return file;
  } catch (error) {
    console.error("Error downloading file", error);
  }
};

const uploadFiles = async (
  fileGroups: FileGroup[],
  token?: string
): Promise<{ rowGuid: string; Id: number | string }[] | undefined> => {
  const formData = new FormData();
  formData.append("Form Description", "Files");
  let index = 0;
  fileGroups.forEach((group) => {
    group.bundles?.forEach((bundle) => {
      bundle.files?.forEach((file) => {
        if (file) {
          formData.append(`Files[${index}].FileName`, file.name);
          formData.append(`Files[${index}].BundleId`, bundle.id);
          formData.append(`Files[${index}].rowGuid`, file.id);
          formData.append(`Files[${index}].FileTypeId`, group.id.toString());
          formData.append(`Files[${index}].Position`, file.position.toString());
          if (file.dbId)
            formData.append(`Files[${index}].Id`, file.dbId.toString());
          if (!file.dbId)
            formData.append(`Files[${index}].File`, file.fileObject);
          if (bundle.values.comments)
            formData.append(
              `Files[${index}].Comments`,
              bundle.values.comments.toString()
            );
          if (bundle.values.dateFrom)
            formData.append(
              `Files[${index}].DateFrom`,
              bundle.values.dateFrom.toString()
            );
          if (bundle.values.dateTo)
            formData.append(
              `Files[${index}].DateTo`,
              bundle.values.dateTo.toString()
            );
          if (bundle.values.issuedAt)
            formData.append(
              `Files[${index}].IssuedAt`,
              bundle.values.issuedAt.toString()
            );
          if (bundle.values.issuedBy)
            formData.append(
              `Files[${index}].IssuedBy`,
              bundle.values.issuedBy.toString()
            );
          if (bundle.values.number)
            formData.append(
              `Files[${index}].Number`,
              bundle.values.number.toString()
            );
          if (bundle.values.common)
            formData.append(
              `Files[${index}].CommonId`,
              bundle.values.common.toString()
            );
          if (bundle.values.checkboxChecked)
            formData.append(
              `Files[${index}].Checked`,
              bundle.values.checkboxChecked.toString()
            );
          if (bundle.values.approvedAt)
            formData.append(
              `Files[${index}].ApprovedAt`,
              bundle.values.approvedAt.toString()
            );
          if (bundle.values.approvedBy)
            formData.append(
              `Files[${index}].ApprovedBy`,
              bundle.values.approvedBy.toString()
            );
          if (bundle.values.approvedComment)
            formData.append(
              `Files[${index}].ApprovedComment`,
              bundle.values.approvedComment.toString()
            );
          if (bundle.values.rejectedAt)
            formData.append(
              `Files[${index}].RejectedAt`,
              bundle.values.rejectedAt.toString()
            );
          if (bundle.values.rejectedBy)
            formData.append(
              `Files[${index}].RejectedBy`,
              bundle.values.rejectedBy.toString()
            );
          if (bundle.values.rejectedComment)
            formData.append(
              `Files[${index}].RejectedComment`,
              bundle.values.rejectedComment.toString()
            );
          if (bundle.status)
            formData.append(`Files[${index}].Status`, `${bundle.status}`);
          if (bundle.reviewComments)
            formData.append(
              `Files[${index}].ReviewComments`,
              bundle.reviewComments
            );
          index++;
        }
      });
    });
  });

  const options: string | object = isNil(token)
    ? ""
    : {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  const fileIds = await files.uploadFiles(formData, options);
  return fileIds;
};

const deleteFile = async (
  dbId: string | number,
  projectTableId: number,
  recordId: number | null,
  recordGuid: string | null,
  token: string | null
) => {
  try {
    if (recordId === null && recordGuid === null) {
      return;
    }
    const options = isNil(token)
      ? ""
      : {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
    await deleteFileWithId(
      { fileId: dbId, projectTableId, recordId, recordGuid },
      options
    );
  } catch (error) {
    throw new Error(error?.toString());
  }
};

const deleteBundle = async (
  bundleId: string,
  projectTableId: number,
  recordId: number | null,
  recordGuid: string | null,
  token: string
) => {
  try {
    if (recordId === null && recordGuid === null) {
      return;
    }
    const options = isNil(token)
      ? ""
      : {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
    await deleteFileWithId(
      { BundleId: bundleId, projectTableId, recordId, recordGuid },
      options
    );
  } catch (error) {
    throw new Error(error?.toString());
  }
};

export { formatFiles, downloadFile, uploadFiles, deleteFile, deleteBundle };

import {
  FileProviderProps,
  FileContextType,
  FileGroup,
  FileAction,
  FileBundle,
  IdentifiedFile,
  FileControl,
} from "../types";
import { useReducer, useMemo, useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import fileDownload from "js-file-download";

const useFileProvider = (props: FileProviderProps): FileContextType => {
  const { downloadFile, onDeleteBundle, onDeleteFile, uploadFiles } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isDirty, setIsDirty] = useState(false);

  const filesReducer = useCallback(
    (state: FileGroup[], action: FileAction): FileGroup[] => {
      switch (action.type) {
        case "INITIALIZE":
          return action.payload.fileGroups;
        case "ADD_BUNDLE":
          setIsDirty(true);
          return state.map((group) => {
            if (group.id === action.payload.groupId) {
              return {
                ...group,
                bundles: [...group.bundles, action.payload.bundle],
              };
            }
            return group;
          });
        case "UPDATE_BUNDLE":
          return state.map((group) => {
            return {
              ...group,
              bundles: group.bundles?.map((bundle) => {
                if (bundle.id === action.payload.bundleId) {
                  return { ...bundle, values: action.payload.values };
                }
                return bundle;
              }),
            };
          });
        case "REMOVE_BUNDLE":
          return state.map((group) => {
            return {
              ...group,
              bundles: group.bundles?.filter(
                (bundle) => bundle.id !== action.payload.bundleId
              ),
            };
          });
        case "ADD_FILE":
          setIsDirty(true);
          return state.map((group) => {
            return {
              ...group,
              bundles: group.bundles?.map((bundle) => {
                if (bundle.id === action.payload.bundleId) {
                  return {
                    ...bundle,
                    files: [...bundle.files, ...action.payload.files],
                  };
                }
                return bundle;
              }),
            };
          });
        case "UPDATE_FILE":
          return state.map((group) => {
            return {
              ...group,
              bundles: group.bundles?.map((bundle) => {
                return {
                  ...bundle,
                  files: bundle.files?.map((file) => {
                    if (file.id === action.payload.fileId) {
                      return { ...file, ...action.payload.file };
                    }
                    return file;
                  }),
                };
              }),
            };
          });
        case "REMOVE_FILE":
          return state.map((group) => {
            return {
              ...group,
              bundles: group.bundles?.map((bundle) => {
                return {
                  ...bundle,
                  files: bundle.files?.filter(
                    (file) => file.id !== action.payload.fileId
                  ),
                };
              }),
            };
          });
        default:
          return state;
      }
    },
    []
  );

  const [fileGroups, dispatch] = useReducer(filesReducer, []);

  const initialize = (fileGroups: FileGroup[]) => {
    dispatch({ type: "INITIALIZE", payload: { fileGroups } });
  };

  const addBundle = (groupId: string | number) => {
    const bundle: FileBundle = {
      id: uuidv4(),
      files: [],
      values: {},
      saved: false,
    };
    dispatch({ type: "ADD_BUNDLE", payload: { groupId, bundle } });
  };

  const updateBundle = (
    bundleId: string,
    values: { [key: string]: string | number | boolean | undefined }
  ) => {
    dispatch({ type: "UPDATE_BUNDLE", payload: { bundleId, values } });
  };

  const deleteBundle = async (bundleId: string) => {
    try {
      const saved = fileGroups
        .flatMap((group) => group.bundles)
        .find((bundle) => bundle.id === bundleId)?.saved;
      if (onDeleteBundle && saved) {
        await onDeleteBundle(bundleId);
      }
      dispatch({ type: "REMOVE_BUNDLE", payload: { bundleId } });
      enqueueSnackbar("Τα αρχεία διαγράφηκαν.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Error deleting attachment: ${error}`, {
        variant: "error",
      });
    }
  };

  const addFiles = (bundleId: string, files: IdentifiedFile[]) => {
    dispatch({ type: "ADD_FILE", payload: { bundleId, files } });
  };

  const updateFile = (fileId: string, file: Omit<IdentifiedFile, "id">) => {
    dispatch({ type: "UPDATE_FILE", payload: { fileId, file } });
  };

  const getFile = (fileId: string) => {
    const bundles = fileGroups.flatMap((group) => group.bundles ?? []);
    const files = bundles.flatMap((bundle) => bundle.files ?? []);
    return files.find((file) => file.id.toUpperCase() === fileId.toUpperCase());
  };

  // if a single id is used for both db and client (no dbId used),
  // the check should be on file.saved instead
  // and the call to onDeleteFile should be with file.id
  const deleteFile = async (fileId: string) => {
    try {
      const fileDbId = getFile(fileId)?.dbId;
      if (onDeleteFile && fileDbId) {
        await onDeleteFile(fileDbId);
      }
      dispatch({ type: "REMOVE_FILE", payload: { fileId } });
      enqueueSnackbar("Το αρχείο διαγράφηκε", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Σφάλμα κατά την διαγραφή αρχείου: ${error}`, {
        variant: "error",
      });
    }
  };

  const getFileObject = async (fileId: string) => {
    try {
      const file = getFile(fileId);
      if (!file?.dbId) {
        throw new Error(`Το αρχείο με Id: ${fileId} not found`);
      }
      return await downloadFile(file.dbId, file.type);
    } catch (error) {
      console.error(error);
    }
  };

  const validateFiles = () => {
    return true;
  };

  const uploadFileGroups = async () => {
    const fileRes = await uploadFiles(fileGroups);
    const fileIds = fileRes?.data;
    if (!!fileIds) {
      fileIds?.forEach((fid) => {
        const currFile = getFile(fid.rowGuid);
        if (!!currFile) {
          updateFile(fid.rowGuid, { ...currFile, dbId: fid.id, saved: true });
        }
      });
    }
    return fileRes;
  };

  const downloadTemplate = async (dbId: number | string, name: string) => {
    try {
      const data = await downloadFile(dbId, "application/pdf");
      if (data) {
        fileDownload(data, name);
      }
    } catch (error) {
      console.error("Σφάλμα κατά την λήψη προτύπου", error);
    }
  };

  const control: FileControl = {
    initialize,
    addBundle,
    updateBundle,
    deleteBundle,
    addFiles,
    updateFile,
    deleteFile,
    getFileObject,
    uploadFileGroups,
    validateFiles,
    downloadTemplate,
    isDirty,
    setIsDirty,
  };

  return { fileGroups, control };
};

export default useFileProvider;
